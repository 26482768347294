import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CryptoJS from "crypto-js";
import "./ProductInfo.css";
import { useSelector } from "react-redux";

const ProductInfo = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(false);

  //!NEW STATE
  const [playerId, setPlayerId] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");

  //? =========================================== LOCATION
  //? =========================================== LOCATION

  //? Getting IP ADDRESS
  const [location, setLocation] = useState({
    ipAddress: null,
    latitude: null,
    longitude: null,
    address: {},
  });
  const [allowedLocation, setAllowedLocation] = useState(false);

  async function fetchIP() {
    try {
      const response = await fetch("https://api.ipify.org");
      const data = await response.text();
      setLocation((prevLocation) => ({ ...prevLocation, ipAddress: data }));
    } catch (error) {
      console.error("Failed to fetch IP:", error);
    }
  }

  useEffect(() => {
    fetchIP();
  }, []);

  //? getting location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation((prevLocation) => ({
            ...prevLocation,
            latitude,
            longitude,
          }));
          setAllowedLocation(true);
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
          try {
            const response = await fetch(url);
            const data = await response.json();
            setLocation((prevLocation) => ({
              ...prevLocation,
              address: data.address,
            }));
          } catch (error) {
            console.error("Error fetching data:", error);
            setError("Error fetching data");
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  //? =========================================== LOCATION
  //? =========================================== LOCATION

  function setPriceAndId(amount) {
    const price = product?.cost?.find((item) => item.amount === amount)?.price;
    setSelectedPrice(price);

    const id = product?.cost?.find((item) => item.amount === amount)?.id;
    setProductId(id);
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product", {
        id: "660d8474ea5b27a0fb1fc226",
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice = res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const numbers = "01234567"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = randomNumbers.join("");
    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      // setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.region,
        userid: userId,
        zoneid: zoneId,
        productid: idPro,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // Buy Product
  async function handleBuy(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product.region + "#" + amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://infinitegamer.com.de/api/payment/check-api-upi-order?orderId=${orderId}`,
        txn_note: userId + "#" + zoneId + "#" + productId,
      };
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        localStorage.removeItem("cart");
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <div className="p-info-container area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="pro-img">
          {product?.images && product?.images.length > 0 && (
            <img
              src={`https://infinitegamer.com.de/${product?.images[showImage]}`}
              alt=""
            />
          )}
        </div>
        <div className="pro-content">
          <h2>{product?.name}</h2>
          <h6>⚡Instant Recharge⚡</h6>
        </div>
      </div>
      <div className="package-details">
        <div className="d-block d-lg-none pro-desc-container">
          <div className="pro-desc">
            <span>{product?.desc}</span>
          </div>
        </div>
        <div className="package-container pt-0">
          {product?.cost?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setAmount(item.amount);
                  setPriceAndId(item.amount);
                }}
                key={index}
                className={`amount ${amount === item?.amount && "active"}`}
              >
                <span>
                  <small>{item.amount}</small>
                </span>
              </div>
            );
          })}
        </div>
        <div className="order-info">
          <div className="pack-info">
            <span>Order Information</span>
            {product?.api === "yes" ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag text-white"
                  type="text"
                  name="zoneid"
                  placeholder="Zone ID"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                {!playerCheck && (
                  <button className="p-check-btn" onClick={handleCheckPlayer}>
                    Check Username
                    {loading && (
                      <div
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
                <span className="text-danger">
                  {error &&
                    "First Check your username by clicking check button"}
                </span>
                <div className="ps-2 text-success">
                  {playerCheck && "Username: " + playerCheck}
                </div>
              </>
            ) : (
              <input
                className="player-tag"
                type="text"
                name="playerId"
                placeholder={
                  product?.api === "no" && product?.category === "Game"
                    ? "Player tag - #XXXXX"
                    : product?.category === "Premium Accounts"
                    ? "Enter email or Whatsapp Number"
                    : product?.category === "Social Media Services"
                    ? "Enter Link"
                    : ""
                }
                onChange={(e) => setPlayerId(e.target.value)}
                value={playerId}
              />
            )}
          </div>
          <div className="mt-4 pack-info mb-5 mb-lg-5">
            <div className="title">
              <span>Total</span>
              <div className="price ">
                {selectedPrice !== null ? (
                  <h3 className="m-0 mt-3">
                    <b>Rs. {selectedPrice}</b>
                  </h3>
                ) : (
                  "Select an amount to see the price"
                )}
              </div>
            </div>
            {!allowedLocation ? (
              <button
                className="p-check-btn"
                onClick={() => navigate("/contact")}
              >
                Contact Admin
              </button>
            ) : !user ? (
              <button
                className="p-check-btn"
                onClick={() => navigate("/login")}
              >
                Please Login First
              </button>
            ) : product?.stock === "no" ? (
              <button className="p-check-btn" style={{ opacity: "0.7" }}>
                Out of Stock
              </button>
            ) : playerCheck === null ? (
              ""
            ) : (
              <button onClick={handleBuy} className="p-check-btn">
                Buy Now
              </button>
            )}
          </div>
        </div>
        <div className="pro-desc-container d-none d-lg-block">
          <div className="pro-desc">
            <span>{product?.desc}</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
